<template>
    <div>
        <v-container>
            <v-row>
                <v-col class="left-sidebar py-0 mr-3">
                    <v-row class="mb-3 mb-1 mt-0">
                        <v-col class="sectors content-block pa-2">
                            <sectors></sectors>
                        </v-col>
                    </v-row>
                </v-col>                    
                <v-col class="main pa-0 content-block">

                    <v-row no-gutters>
                        <v-col cols="12" class="pa-2  pb-0">
                            <v-card elevation="0">
                                <v-row no-gutters>
                                    <v-col cols="7">
                                        <v-card-title>SPY</v-card-title>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                
                                    <v-col class="flex-shrink-0 pa-3 text-right" style="max-width: 300px;">
                                        <v-btn @click="getSpyData" depressed color="neutral" class="ml-3" small><v-icon small>mdi-reload</v-icon></v-btn>
                                    </v-col>
                                
                                </v-row>
                            </v-card> 
                        </v-col>
                    </v-row>
                    <v-row no-gutters>    
                        <v-col  class="pa-2">
                            <v-card elevation="0">
                                <v-row no-gutters>                           
                                    <v-col  class="subtitle-2 pa-3" cols="6"  >{{selectedTimeframe | capitalize}} Chart</v-col>
                                    <v-col cols="6" class="text-right pa-2" >
                                        <v-btn-toggle mandatory color="primary"  v-model="selectedTimeframe">
                                            <v-btn  outlined small value="minute">M5</v-btn>
                                            <v-btn  outlined small value="hour">H1</v-btn>
                                            <v-btn  outlined small value="day">D1</v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                    
                                    <v-col cols="12" class="text-right pb-2">
                                        <v-btn-toggle v-show="selectedTimeframe !== 'day'" multiple tile color="primary" dense v-model="lines"  class="mr-3 mb-2">
                                            <v-btn outlined small value="emas">Intraday EMAs</v-btn>
                                            <v-btn outlined small value="intraday_trendlines">Intraday Trendlines</v-btn>
                                            <v-btn outlined small value="intraday_horizontal_support">Intraday Horizontal Support</v-btn>
                                            <v-btn outlined small value="hloc">Prevous HOLC</v-btn>
                                            
                                        </v-btn-toggle>

                                        
                                        <v-btn-toggle multiple color="primary"  v-model="lines" class="mr-3">
                                            
                                            
                                            <v-btn outlined small value="trendlines">Daily Trendlines</v-btn>
                                            <!-- <v-btn outlined small value="trendlines">Daily EMAs</v-btn> -->
                                            <v-btn outlined small value="horizontal_support_daily">Daily Horiz. Support</v-btn>
                                            <v-btn outlined small value="smas">Daily SMAs</v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-divider></v-divider>
                            <v-row no-gutters>
                                <v-col  class="pa-0" >
                                    <Chart 
                                        :isLoaded="isLoaded"
                                        :lines="lines" 
                                        :selectedTimeframe="selectedTimeframe"
                                        :dailyChart="dailyChart"
                                        :intradayTrendsChart="intradayTrendsChart"
                                        :intradayChart="intradayChart"
                                        :activeTicker="activeTicker"
                                    />
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                 <v-col  class="pa-0" >
                                    <Pressure />
                                 </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>          
                
            </v-row>
        </v-container>
    </div> 
</template>

<script>
    
    import Sectors from "./Sectors"
    import Pressure from "./Pressure"
    import { mapGetters, mapMutations } from 'vuex';

    import Chart from "../dashboard/Chart"
    export default {
        name: 'SPY',
        data: () => ({
            date: '',
            timeframes: {
                day: {},
                minute: {},
                hour: {},
            },
            lines: [],
            selectedTimeframe:'',
            isLoaded: false,
            
            // intradayTrendsChart:{},
            // intradayChart:{},
            activeTicker:{}
        }),
        computed: {
            ...mapGetters({
            }), 
            ...mapMutations({
                
            }), 
            items () {
                
            },
           
            dailyChart(){
                if(!this.timeframes.day) return null
                
                return this.timeframes.day?.daily || {}
            },
            intradayTrendsChart(){
                if(!this.selectedTimeframe || !this.timeframes[this.selectedTimeframe] ) return null;
                return this.timeframes[this.selectedTimeframe]?.daily || {}
            },
            intradayChart(){
                return this.timeframes[this.selectedTimeframe]?.intraday || {}
            },
        },
        components:{
            Sectors,
            Chart,
            RealOnBalanceVolume
            
        },
        async created(){
            const options = {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            };

            var d = new Date();    
            d = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
            this.date = d.toISOString().split('T')[0]
            this.getSpyData();
            this.setUpSpyFeed();
        },
        methods:{
            async getSpyData(){
                this.isLoaded = false;
                let data = await this.api.get(`/tickers/SPY/dashboard`, {
                    ah: true,
                    date: this.date
                });
                this.timeframes = data.results.timeframes;
                this.isLoaded = true;
            },
            setUpSpyFeed(){

            },
        },
        filters:{
            capitalize(string){
                // return string;
                return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
            }
        },
        watch: {
           
        }
    }
</script>
<style scoped>
 .left-sidebar{
    flex: 0 0 450px;    
    
  }
  .content-block{
      background-color: #EEF2F3;
  }
  /* .sectors{
      height: 325px;
  } */
  .rsrw{
      height: 200px;
  }



  .chart {
      /* height: 650px; */
  }
</style>