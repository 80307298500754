<template>

    <v-card elevation="0" class="mt-2">
        <v-col class="subtitle-2 pa-3" >Pressure</v-col>
        <v-divider></v-divider>
        <div v-show="prices.length" :id="`${type}-chart-${activeTicker === 'SPY' ? 'SPY' :  'ticker'}`" style="height: 180px;" ></div>
        <div v-show="!prices.length" class="pa-10">
          <p class="text-body-2 text-center">Retreiving Data</p>
            <v-progress-linear
                color="accent"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        </div>
    </v-card>

</template>

<script>
    import { EventBus } from '../../modules/EventBus.js';

    import { createChart } from 'lightweight-charts';
    import TVChart from "../dashboard/TVChart"
    import {DateTime} from 'luxon';

    import { mapGetters, mapMutations } from 'vuex';
    export default {
        name: 'Pressure',
        data: () => ({
            prices:[],
             chart: {},
             longSeries: {},
             shortSeries: {}, 
             zeroSeries: {}
        
        }),
        filters: { 
            capitalize(string){
                return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
            }
        }, 
        async mounted(){
            EventBus.$on('reload_stock', this.getSpy);

            this.getSpy();
        }, 
        props: ['timeseries',  'chartDate', 'activeTicker'], 
        destroyed(){
            EventBus.$off('reload_stock', this.getSpy);
        }, 
        computed:{
             ...mapGetters({
                isLoaded: 'dashboardStore/isLoaded'
               
            }), 
            pressure_long(){
                return this.prices.map(p => {
                    return {
                        time: +p.time,
                        value: p.pressure_long
                    }
                    
                })
            },
            pressure_short(){
                return this.prices.map(p => {
                      return {
                        time: +p.time,
                        value: p.pressure_short
                    }
                    
                })
            },
             zeroline(){
                return this.prices.map(p => {
                      return {
                        time: +p.time,
                        value: 0
                    }
                    
                })
            },
            power_long(){
                return this.prices.map(p => {
                    return {
                        time: +p.time,
                        value: p.power_long
                    }
                    
                })
            },
            power_short(){
                return this.prices.map(p => {
                      return {
                        time: +p.time,
                        value: p.power_short
                    }
                    
                })
            },
        },
        methods: {
            addPrice(data){
                
                this.prices.push(data)
            }, 
            async getSpy(){
                let results = await this.api.get(`/tickers/pressure`, {
                    ticker: this.activeTicker,
                    date: this.chartDate
                });
                 this.prices = results.volume;
                 this.createChart();
            }, 
            resetData(){
                // this.stockSeries = null;
                // this.spySeries = null;
                // this.etfSeries = null;
                // this.standardSeries = null;

                try{
                    this.chart.remove();
                } catch(err){
                  //   console.log(err)
                }
            }, 
            createChart(){

                this.resetData();
                let pchart = document.getElementById(`${type}-chart-${this.activeTicker === 'SPY' ? 'SPY' :  'ticker'}`)
                
                this.chart = createChart(pchart, { 
                    width: 640, 
                    height: 180,	
                    timeScale: {
                        lockVisibleTimeRangeOnResize: true,
                        // borderVisible: false,
                        fixLeftEdge: true,
                        fixRightEdge: true,
                        tickMarkFormatter: (time) => {
                            
                            let datetime = DateTime.fromMillis(time);
                
                            
                            // if(this.selectedTimeframe === 'day'){
                            //     return datetime.toLocaleString();
                            // } else {
                            
                                return datetime.toLocaleString(DateTime.TIME_SIMPLE);
                            // }
                        } 
                    },
                    priceScale: {
                         borderVisible: false,
                         
                    },	
                    priceLineVisible: false,
                    vertLine: {
                        visible: false,
                        labelVisible: false,
                    },
                    
                    grid: {
                        vertLines: {
                            visible: false, 
                        },
                        horzLines: {
                            color: '#f0f0f0', 
                        }
                    },
                    //handleScroll : false,
                    axisPressedMouseMove : false

                });

                const chartOptions = { layout: { textColor: 'black', background: { type: 'solid', color: 'white' } } };


                this.shortSeries = this.chart.addLineSeries({ color: '#2962FF', lineWidth: 2, priceLineVisible: false });
                
                this.shortSeries.setData(this.pressure_short);

                
                this.longSeries = this.chart.addLineSeries({ color: '#5d5d5e', lineWidth: 2, priceLineVisible: false });
                this.longSeries.setData(this.pressure_long);


                this.zeroSeries = this.chart.addLineSeries({ color: '#cccccc', lineWidth: 2, priceLineVisible: false });
                
                this.zeroSeries.setData(this.zeroline);


        


            },
            // setTimeSeries(data){
            //     if(!this.chart.timeScale) return 
            //     this.chart.timeScale().setVisibleRange(data)
            // }
        },
        watch: {
            chartDate(){
                this.getSpy();
            },
            //  timeseries(data){
            //     this.setTimeSeries(data);
            // }
        }
    }
</script>