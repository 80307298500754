<template>
    <v-card elevation="0">



        <v-row no-gutters>
            <v-col class="subtitle-2 pa-3" >Sector Performance</v-col>
            <v-col class="text-right pa-2" >

                <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        min-width="500"
                        offset-x
                        >
                        <template v-slot:activator="{ on }">
                            <v-btn color="#2196f3" v-on="on" icon >
                                <v-icon>mdi-view-list</v-icon> 
                            </v-btn>
                        </template>

                        <v-card>
                            <!-- <v-list-item>
                            <v-list-item-content> --> 

                                <v-card-title>Toggle Sectors</v-card-title>
                                <v-card-subtitle>Adjust the sectors that appear</v-card-subtitle>
                            <!-- </v-list-item-content> -->
                             <!-- <v-btn @click="resetSectors" icon color="primary">
                                <v-icon class="mr-1">mdi-trash-can</v-icon>
                            </v-btn>
                             -->
                            <!-- </v-list-item> -->
                            
                
                            <v-divider />
                            <v-container>
                                <v-checkbox 
                                    dense
                                    v-model="sector_toggle"
                                    hide-details
                                    @change="toggleAllSectors"
                                >
                                    <template v-slot:label>
                                        Toggle All Sectors
                                    </template>
                                </v-checkbox>


                                <v-checkbox 
                                    :value="sector.symbol"
                                    dense
                                    hide-details
                                    v-for="sector in sectors" :key="sector.symbol"
                                    v-model="visible_sectors"
                                    @change="createChart"
                                >
                                    <template v-slot:label>
                                        <div style="display: inline-block; width: 75px;"><v-chip class="ml-2" small :color="sector_colors[sector.symbol]" dense label> {{sector.symbol}}</v-chip></div>{{sector.name}}
                                    </template>
                                    </v-checkbox>
                            </v-container>
                        </v-card>
                </v-menu> 
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>

            <v-col>
            
                <div v-show="!loading" id="sectors-spy-chart" style="height: 300px;"></div>
                <div v-if="loading" class="pa-10">
                <p class="text-body-2 text-center">Retreiving Data</p>
                    <v-progress-linear
                        color="accent"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </div>
            </v-col>   
        </v-row>
    </v-card>
    
        
    
</template>

<script>
    import { EventBus } from '../../modules/EventBus.js';
     
    import { mapGetters, mapMutations } from 'vuex';
    export default {
        name: 'Sectors',
        data: () => ({
            loadSettings: false,
            menu: false,
            sectors: {},
            loading: false, 
            period: 15,
            sector_toggle: true, 
            chart: {},
            chart_options: {
                
                plotOptions: {
                     states: {
                        hover: {
                            enabled: false
                        },
                        inactive: {
                            opacity: 1,
                        }
                    }, 
                    series: {
                        animation: false,
                        enableMouseTracking: false,
                        label: false
                    }
                }, 
                credits: {
                    enabled: false
                },
        
                exporting: {
                    enabled: false
                }, 
                scrollbar: { enabled: false },
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                
                yAxis: {
                
                    labels:{
                        enabled: false
                    },
                    plotLines: [],
                },
                xAxis: {
                    plotLines: [],
                    labels: {
                        enabled: false
                    }
                },
                series: [],
                
            },
            visible_sectors: [],
        }),
        computed: {
             ...mapGetters({
                sector_colors: 'dashboardStore/getSectorColors'
            }), 
            
        },
        async created(){
            // this.$store.dispatch( 'dashboardStore/subscribeETFs');
            
            this.loading = true;
            await this.getData();
            this.loading = false;
            EventBus.$on('price_update', this.addPrice);

           // this.interval = setInterval(this.getData, 1000 * 60)
        }, 
        destroyed(){
            EventBus.$off('price_update', this.addPrice);
        }, 
        methods: {
            toggleAllSectors(){
                
                this.visible_sectors = [];
                if(this.sector_toggle){
                    for(let sector in this.sectors){
                        this.visible_sectors.push(sector);
                    }
                    
                } else {
                    this.visible_sectors.push('SPY');
                }
                this.createChart();
            }, 
            async getData(){
                
                let results = await this.api.get(`/tickers/sectors-spy`, { period: this.period });

                this.sectors = results.sector_data;
                if(!this.visible_sectors.length){
                    for(let etf in this.sectors){
                        this.visible_sectors.push(etf);
                    }
                }
                this.createChart();
            },
            createChart(){

                try{
                    this.chart_options.series = [];
                    this.chart.destroy();
                } catch(err){

                }

                let _t = this;
                for(let etf in this.sectors){
                    if(this.visible_sectors.find(v => v === etf)){

                        this.chart_options.series.push({
                            type: 'line',
                            name: etf,
                            data: this.sectors[etf].data.map(d => d.value),
                            color: this.sector_colors[etf],
                        });
                    }
                }
                this.chart = Highcharts.stockChart('sectors-spy-chart', this.chart_options); 
            }, 
            addPrice(data){ 
            },
           roundDownTo: (roundTo) => x => Math.floor(x / roundTo) * roundTo,
        }
        
    }
 </script> 